import { Component, ReactNode } from "react";

interface PropsCard {
    title: string;
    p: string;
}

export class CardService extends Component<PropsCard> {

    constructor(props:PropsCard){
        super(props);

        this.state = {
            title: this.props.title || " ",
            p: this.props.p || " ",
        }
    }

    render(): ReactNode {

        return (
            <div className="">
    
                <div className="bg-white shadow-black shadow-md border rounded-lg p-2 lg:w-96 lg:h-72">
                    <li className="text-purple-primary font-bold">{this.props.title}</li>
                    <p className="text-sm text-slate-500">
                        {this.props.p}
                    </p>
                </div>
    
            </div>
        )
        
    }
}