import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  Defi,
  SignUp,
  SecurityAudit,
  TokensSolutions,
  NftSolutions,
  Portfolio,
  MintPage
} from "../containers";
import { HomeReseller } from "../containers/Reseller/HomeReseller";
import { PrivateRoute } from "./PrivateRoute";
import { About } from "../containers/Company/About";
import { LoadingPage } from "../containers/LoadingPage";

export const Routers = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/loading" element={<LoadingPage/>}/>
        <Route path="/defi" element={<Defi />} />
        <Route path="/resell/signup" element={<SignUp />} />
        <Route path="/security" element={<SecurityAudit />} />
        <Route path="/tokens" element={<TokensSolutions />} />
        <Route path="/nfts" element={<NftSolutions />} />
        <Route path="/about" element={<About/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/mint" element={<MintPage/>}/>
        <Route
          path="/resell"
          element={
            <PrivateRoute>
              <HomeReseller />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};
