import Logo from "../../assets/img/logo-nobg.png";
import { useNavigate } from "react-router-dom";

export const LoadingPage = ():JSX.Element => {

    const navigate = useNavigate();

    return (
        <div className="bg-black min-h-screen">
            <h1 className="text-white text-center pt-20 text-2xl font-bold">
                Lançamento em breve...
            </h1>

            <div className="flex flex-col justify-center items-center">
                <img src={Logo} alt="icon"/>
                <button
                className="bg-white w-32 p-1 rounded-md"
                onClick={() => navigate("/")}>Voltar</button>
            </div>
        </div>
    );
}