import { Card } from "../../components/Card";
import { useState, useEffect } from "react";
import { Carousel } from "../../components/Carrosel";
import { NetworksMobile } from "../../components/NetworksMobile";
import { useTranslation } from "react-i18next";

export const Benefits = (): JSX.Element => {
    const { t } = useTranslation();
    const [state, setState] = useState<any>([]);

    // Atualiza os textos sempre que o idioma mudar
    useEffect(() => {
        setState([
            { title: t('desenvolva_segurança.title'), paragraph: t('desenvolva_segurança.paragraph') },
            { title: t('transforme_ideias.title'), paragraph: t('transforme_ideias.paragraph') },
            { title: t('enriqueça_experiencia.title'), paragraph: t('enriqueça_experiencia.paragraph') },
            { title: t('amplas_tecnologias.title'), paragraph: t('amplas_tecnologias.paragraph') }
        ]);
    }, [t]); // Dependência em 't' garante que o efeito seja executado ao mudar de idioma

    return (
        <div className="h-auto bg-gradient-secundary flex flex-col items-center mt-32 pb-10">
            <div className="flex flex-col gap-5 lg:flex-row lg:gap-4 p-10">
                {state.map((info: any, index: number) => (
                    <Card 
                        key={index}
                        image={info.image} 
                        title={info.title} 
                        paragraph={info.paragraph} 
                    />
                ))}
            </div>

            <div className="w-[50%] mt-72 justify-center hidden lg:flex lg:pb-20">
                <Carousel />
            </div>

            <div className="flex lg:hidden">
                <NetworksMobile />
            </div>
        </div>
    );
};
