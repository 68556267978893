import BASE from '../../assets/img/base.svg';
import ETH from '../../assets/img/eth.svg';
import POLYGON from '../../assets/img/polygon.svg';
import BINANCE from '../../assets/img/binance.svg';
import ARB from '../../assets/img/arb.svg';
import AVAX from '../../assets/img/avalanche.svg';
import TRON from '../../assets/img/tron.svg';
import GN from '../../assets/img/gn.svg';
import Luiztools from '../../assets/img/lt-quadrado.png';
import  Lumx from '../../assets/img/lumxa-redonda.png';

export const NetworksMobile = (): JSX.Element => {

    return (
        <div className='flex items-center justify-center gap-32 mt-20'>

            <div className='flex flex-col items-center gap-3'>
                <img className='w-20' src={BASE} alt='icon'/>
                <img className='w-10' src={ETH} alt='icon'/>
                <img className='w-10'  src={POLYGON} alt='icon'/>
                <img className='w-10' src={BINANCE} alt='icon'/>
                <img className='w-10' src={Luiztools} alt='icon'/>
            </div>

            <div className='flex flex-col items-center gap-3'>
                <img className='w-10' src={ARB} alt='icon'/>
                <img className='w-10' src={AVAX} alt='icon'/>
                <img className='w-10' src={TRON} alt='icon'/>
                <img className='w-10' src={GN} alt='icon'/>
                <img className='w-14' src={Lumx} alt='icon'/>
            </div>
        </div>
    )
}