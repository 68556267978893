import { CardService } from "../../../components/CardService";

export const Benefits = (): JSX.Element => {
  return (
    <div className="h-screen bg-white mt-20 lg:h-[600px] lg:pt-32">
      <div className="flex flex-col gap-10 px-2 lg:flex-row justify-center">
        <CardService
          title="Descentralização e Controle  "
          p="Com as soluções NFT da Next, você tem controle total sobre seus ativos digitais sem intermediários. A descentralização do blockchain garante que você gerencie seus ativos de forma independente e segura. Perfeito para empreendedores, startups e investidores digitais. "
        />

        <CardService
          title="Royalties Automatizados"
          p="Com NFTs, você pode configurar royalties automáticos para cada venda subsequente de seu ativo digital. Assim, você continua a ganhar mesmo após a venda inicial. Ideal para artistas, músicos e criadores de conteúdo que desejam garantir ganhos contínuos."
        />

        <CardService
          title="Segurança e Transparência"
          p="As soluções NFT da Next garantem total segurança e transparência nas transações. Todas as operações são registradas em blockchain, proporcionando rastreabilidade completa e confiança. Perfeito para transações financeiras, contratos inteligentes e proteção de propriedade intelectual."
        />
      </div>
    </div>
  );
};
