import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
export const NavBar = ():JSX.Element => {

    return (
        <div className="bg-white w-full h-20 rounded-t-xl relative flex justify-center">
            <div className='flex items-center gap-32'>
                <a href="/resell"><KeyboardArrowLeftIcon/></a>
                <a><HomeIcon/></a>
                <button><PowerSettingsNewIcon/></button>
                
            </div>
        </div>
    );
}