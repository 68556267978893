import { useState } from 'react';
import {check} from '../../service/Blockchain';
import CertikAnimate from '../../assets/animates/CertikAnimate.gif';
import { useTranslation } from 'react-i18next';

export const Certic = (): JSX.Element => {

    const[code, setcode] = useState<number>(0);
    const[validate, setValidate] = useState([]);
    const { t } = useTranslation();

    async function checkValidate(){
        try {
            const result = await check(Number(code));
            setValidate(result);
            console.log(result)
        }
        catch(err){
            return err;
        }
    }
       
    return (
        <div className="h-auto bg-white mt-36">
            <div className='flex flex-col justify-center items-center pb-2'>
                <h3 className='text-center text-xl font-semibold text-purple-primary'>Badges</h3>
                <h4 className='text-center text-2xl font-bold lg:text-3xl'>{t('nxcertify.title')}</h4>
                <p className='text-sm text-center mt-2 text-gray-500 lg:text-xl lg:w-[1000px]'>
                    {t('nxcertify.p')}
                </p>
            </div>

        <div className='flex flex-col lg:flex-row justify-around lg:mt-10'>
            <div className='flex flex-col justify-center items-center'>
                <img className='p-5 rounded-[40px]' src={CertikAnimate} alt='gif'/>
            </div>


                <div className='flex flex-col justify-center items-center lg:w-6/12'>
                    <label className='font-semibold'>{t('nxcertify.label')}</label>
                    <input onChange={(e) => setcode(Number(e.target.value))} className='border-2 w-10/12 rounded-md p-1'/>

                    {
                        validate.length > 0 &&
                        <div className='border-2 w-72 h-32 mt-5 rounded-md'>
                            <div className='p-2'>
                                <p>{t('nxcertify.area.name')}: {validate[0]}</p>
                                <p>{t('nxcertify.area.validate')}: {validate[1] === false ? <span className='text-red-600'>{t('nxcertify.area.error')}</span> : <span className='text-green-500'>{t('nxcertify.area.success')}</span>}</p>
                            </div>
                        </div>
                    }


                    <button onClick={checkValidate} className='bg-purple-primary w-32 p-1 mt-2 rounded-md text-white font-medium lg:mb-10'>{t('nxcertify.button')}</button>

                    <div className='p-5 mt-2'>
                            <div className=' p-2 rounded-md'>
                                <h5 className='text-xl font-bold'>{t('aumento_confianca.title')}</h5>
                                <p className='text-gray-500 text-sm lg:text-lg'>
                                   {t('aumento_confianca.paragraph')}
                                </p>
                            </div>
                            <div className=' p-2 rounded-md'>
                                <h5 className='text-xl font-bold'>{t('reduz_riscos.title')}</h5>
                                <p className='text-gray-500 text-sm lg:text-lg'>
                                    {t('reduz_riscos.paragraph')}
                                </p>
                            </div>
                            <div className=' p-2 rounded-md'>
                                <h5 className='text-xl font-bold'>{t('valor_competitivo.title')}</h5>
                                <p className='text-gray-500 text-sm lg:text-lg'>
                                    {t('valor_competitivo.paragraph')}
                                </p>
                            </div>
                    </div>

                </div>
            </div>       
        </div>
    )
}