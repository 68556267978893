import GetAppIcon from '@mui/icons-material/GetApp';
import Capa from '../../assets/img/capa-book-2.png';
import { useTranslation } from 'react-i18next';

export const BannerWhitepaper = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="bg-black h-auto lg:flex lg:flex-row-reverse lg:justify-center lg:items-center lg:h-[500px] flex flex-col items-center">
            <img className='lg:w-72 w-52 mt-5 p-2' src={Capa} alt='capa' />

            <div className="p-2 flex flex-col gap-1">
                <h2 className='text-white text-2xl font-bold lg:text-3xl'>
                    {t('entenda_nossas_solucoes.title')}
                </h2>

                <p className='text-white mt-2 lg:w-[50%]'>
                    {t('entenda_nossas_solucoes.paragraph')}
                </p>

                <a 
                    href='https://pdf-nextchain.s3.us-east-1.amazonaws.com/Next+Chain+Blockchain+Solutions+LTDA.pdf' 
                    className='bg-purple-primary px-5 rounded-md text-white mt-10 p-2 w-36 flex items-center justify-center'
                >
                    {t('entenda_nossas_solucoes.download')} <GetAppIcon />
                </a>
            </div>
        </div>
    );
};
