import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Component, ReactNode } from 'react';

interface PropsCard {
    image: any;
    title: string;
    paragraph: string;
    item1: string;
    item2: string;
    item3: string;
    button: string;
    link: string
    icon: any;
    href: string;
}

export class CardProducts extends Component<PropsCard> {

    constructor(props: PropsCard){
        super(props)

        this.state = {
            image: props.image || " ",
            title: props.title || " ",
            paragraph: props.paragraph || " ",
            item1: props.item1 || " ",
            item2: props.item2 || " ",
            item3: props.item3 || " ",
            button: props.button || " ",
            link: props.link || " ",
            icon: props.icon || " ",
            href: props.href || " ",
        }
    }

    render(): ReactNode {
        return (
        <div className="bg-gradient-secundary h-auto w-[90%] rounded-xl p-2 pb-5 mt-10 
        lg:flex lg:flex-row-reverse lg:w-[65%]  lg:items-center flex flex-col items-center
        lg:h-[650px]
        ">
           <div className='bg-transparent p-2 mt-5 rounded-lg lg:pr-5 lg:w-[800px]'>
            <img className='w-52 rounded-lg hover:scale-125 lg:w-[500px] ' src={this.props.image} alt='icons'/>
           </div>
            
        <div className='lg:p-5'>
            <div className="flex flex-col text-sm mt-2">
                <h3 className="text-white font-bold
                lg:text-3xl
                ">{this.props.title}</h3>
                <p className="text-white lg:text-xl">
                    {this.props.paragraph}
                </p>
            </div>

            <div className='flex flex-col'>
                <p className='flex text-sm text-slate-500 gap-2 mt-10'><CheckCircleIcon/>
                {this.props.item1}
                </p>

                <p className='flex text-sm text-slate-500  gap-2 mt-10'><CheckCircleIcon/>
                {this.props.item2}
                </p>

                <p className='flex text-sm text-slate-500  gap-2 mt-10'><CheckCircleIcon/>
                {this.props.item3}
                </p>
            </div>

            <div className='flex items-center gap-5'>
                <a href={`/${this.props.href}`}
                className='bg-purple-primary p-2 rounded-xl shadow-lg text-white font-semibold mt-5'>
                    {this.props.button}
                </a>

                <a className='p-2 mt-5 text-white font-semibold flex items-center' href={`/${this.props.href}`}>
                {this.props.link}
                {this.props.icon}
                </a>
            </div>
        </div>
            
        </div>
        )
    }
}
