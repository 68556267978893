import { useState } from 'react';
import { HeaderMobile } from "../../../components/HeaderMobile";
import { HeaderComputer } from "../../../components/HeaderComputer";
import DownloadIcon from '@mui/icons-material/Download';
import AboutAnimate from '../../../assets/animates/About.gif';
import { Footer } from '../../../components/Footer';
import { useTranslation } from 'react-i18next';

export const About = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className='h-auto bg-white'>
            <div className="hidden lg:block">
                <HeaderComputer />
            </div> 

            <div className='lg:hidden'>
                <HeaderMobile />
            </div> 

            <div className='p-5 flex flex-col lg:flex-row pb-20'>
                <img className='rounded-xl lg:w-[500px] lg:ml-20' src={AboutAnimate} alt='animate' />

                <div className='flex flex-col w-full'>
                    <h2 className='text-center mt-10 font-bold text-purple-primary'>Whitepaper</h2>
                    <div className='flex justify-center gap-10 mt-10 pl-10'>
                        <a href='https://pdf-nextchain.s3.us-east-1.amazonaws.com/Next+Chain+Blockchain+Solutions+LTDA.pdf' className='flex flex-col justify-center items-center border-2 w-6/12 h-32'>
                            PT-BR
                            <DownloadIcon />
                        </a>
                        <a href='' className='flex flex-col justify-center items-center border-2 p-2 w-6/12 h-32'>
                            EN-US
                            <DownloadIcon />
                        </a>
                    </div>

                    <div className='flex justify-center mt-32'>
                        <mark className='text-xl'>{t('about.come')}</mark>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
