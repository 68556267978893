import BannerImage from '../../assets/img/banner.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './Portfolio.css';
import Git from '../../assets/icon/github.png';
import Figma from '../../assets/icon/figma.png';

import Rice from '../../assets/logos/rc.png';
import Lucky from '../../assets/logos/lucky-logo.png';
import GCoin from '../../assets/logos/gcoin.png';
import CryptoLab from '../../assets/logos/CryptoLab.png';
import SupBuild from '../../assets/logos/superbuild.png';
import ZeCripto from '../../assets/logos/logo.svg';

import { useState, useEffect } from 'react';
import { Footer } from '../../components/Footer';
import { HeaderMobile } from '../../components/HeaderMobile';
import { HeaderComputer } from '../../components/HeaderComputer';


export const Portfolio = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 360 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 360, min: 0 },
            items: 1,
        },
    };

    const [newClient, setClient] = useState(0);
    const [newDesign, setDesign] = useState(0);
    const [newFindings, setFidings] = useState(0);
    const [newSmart, setSmarts] = useState(0);

    const [data] = useState([
        {logo: "https://yt3.googleusercontent.com/UVsnaMFeCV3zPC--3hrSn6cZ0Lj0QvleyQAa9DqyxdQheDb8oapzUYRF9KQ3AT2TviPOD_YUbQ=s900-c-k-c0x00ffffff-no-rj", name: "Axelar Network", audited: true, developed: false, git: Git, link: "https://github.com/nxchaindotlink/axelar-network-audit", active: true},
        {logo: "https://pbs.twimg.com/profile_images/1792429464363970560/Q5CZpxYG_400x400.jpg", name: "Goat Tech", audited: true, developed: false, git: Git, link: "https://github.com/nxchaindotlink/goat-tech-audit", active: true},
        {logo:Rice, name: "RiceSwap", audited: true, developed:true, git: Git, link: "https://github.com/nxchaindotlink/riceswap", fig: Figma, link2: " https://www.figma.com/design/tKVou4vnU9od3zcrCBM46k/riceswap?node-id=0-1&t=2QFQZ6FmSMZkXLXo-1", active: false},
        {logo: "https://play-lh.googleusercontent.com/ssrpBT94LnMy6mgyNbbEj_9SONp8h4lK8HWrN4qR67dyfwIjgNTYJZOSY4ZOL2yC60uY", name: "Freepik", audited: true, developed: false, git: Git, link: "https://br.freepik.com/", active: true},
        {logo: "", name: "Virtus Metaverse", audited: false, developed: true, git: Git, fig: Figma, link: "https://github.com/nxchaindotlink/virtush-interface", link2: "https://www.figma.com/design/1qRxMPM09QwlMEPYV94bxN/virtus?node-id=0-1&t=zRjMVi3Xovtz8tsT-1" , active: true},
        {logo: Lucky, name: "Lucky Token", audited: true, developed: true, git: Git, link: "https://github.com/nxchaindotlink/LuckyToken", active: true},
        {logo: GCoin, name: "GCoin", audited: true, developed: true, git: Git, link: "https://github.com/nxchaindotlink/Gcoin", fig: Figma, link2: "https://www.figma.com/design/bbXhfqfzIRJEKvaVH5CJwN/gcoin?node-id=0-1&t=k6NgKkOQI8fPt7YK-1", active: false},
        {logo: CryptoLab, name: "CryptoLab", audited: true, developed: true, git: Git, link: "", link2: "https://www.figma.com/design/EcdcJYKNPyzH4uSePfw1Md/CryptoLab?node-id=0-1&t=W6Jboj7wl1LIi541-1", fig:Figma, active: false},
        {logo: "", name: "Crypto Donate", audited: false, developed: true, git: Git, link: "https://github.com/nxchaindotlink/CryptoDonate", active: false},
        {logo: SupBuild, name: "Super Build", audited: false, developed: true, fig: Figma, link: "", link2: " https://www.figma.com/design/W68qQv7MRkwq8ZHd0LFltv/SuperBuild?node-id=0-1&t=kBEzOCo5sxzSCgut-1", active: false},
        {logo:"", name: "RaFi Solutions", audited: false, developed: true, fig: Figma, link: "", active: true},
        {logo: ZeCripto, name: "ZéDaCripto", audited: false, developed: true, link: "https://github.com/nxchaindotlink/zedacripto", git: Git, active: false},

    ])

    useEffect(() => {
    
        const clients = 16;
        const design = 52;
        const fidings = 23;
        const smart = 32;
  
        const countUp = (setter: (value:number) => void, target:number, duration:number) => {
          let start = 0;
          const step = Math.ceil(target / (duration / 50));
          const timer = setInterval(() => {
            if (start < target) {
              start += step;
              if (start > target) start = target;
              setter(start);
            } else {
              clearInterval(timer);
            }
          }, 50);
        };
  
        countUp(setClient, clients, 5000);
        countUp(setDesign, design, 5000);
        countUp(setFidings, fidings, 5000);
        countUp(setSmarts, smart, 5000);
  
    },[]);

    return (
        <div className='h-auto  pb-10 bg-[#0F1116] p-2 overflow-x-hidden'>
            <div className="lg:hidden">
                <HeaderMobile/>
            </div>
      
            <div className="hidden lg:block">
                <HeaderComputer />
            </div>

            <div className='flex items-center justify-center'>
                <img className='lg:10/12 lg:rounded-xl' src={BannerImage} alt='icon' />
            </div>

            <article className='flex items-center flex-col mt-10'>
                    <p className='text-white font-bold text-xl'>Mentorship and Consulting</p>
                    <p className='text-white font-bold text-xl'>+5 Clients</p>
            </article>

            <article className='lg:ml-5'>
                <Carousel responsive={responsive} itemClass="carousel-item-padding-40-px" className='mt-10'>
                    {data.map(i=> (
                        <div className='carousel-item'>
                            <div className='bg-purple-primary flex items-center gap-2 h-32 p-2 bg-opacity-10 border border-purple-primary rounded-md'>
                                <img className='w-[50px] h-[55px] ml-3' src={i.logo} alt='logo' />
                                <div className='flex flex-col gap-2 justify-between'>
                                    <label className='font-semibold text-white'>{i.name}</label>
                                    <div className='flex flex-col gap-2'>
                                        { i.developed&&
                                            <p className='
                                            bg-orange-400 border flex justify-center p-1 text-sm border-orange-500 
                                            bg-opacity-20 text-white font-bold rounded-md'>
                                                Developed
                                            </p>
                                        }
                                        { i.audited&&
                                            <p className='
                                            bg-red-400 border p-1 flex justify-center  text-sm
                                             border-red-500 bg-opacity-20 text-white font-bold rounded-md'>
                                                Audited
                                                </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </article>

            <div className='p-2 mt-5 flex justify-center'>
                    <div className='bg-purple-primary w-10/12 h-10 flex justify-around items-center text-white font-bold rounded-md'>
                        <label>Name</label>
                        <label>Contract</label>
                        <label>View</label>
                    </div>
            </div>

            <article className='mt-2 p-2 flex flex-col gap-5 lg:grid lg:grid-cols-3 lg:ml-4'>
                {
                    data.map(i => (
                        <div className='flex justify-between h-32 p-2 border border-purple-primary  rounded-md'>

                                <div className='flex gap-3 items-center'>
                                    <img className='w-10 ml-3' src={i.logo} alt='logo' />

                                        <div className=''>
                                            <label className='font-bold text-white'>{i.name}</label>
                                            <p 
                                                className={
                                                    i.active ? 'bg-green-500 border font-bold w-14 border-green-500 rounded-md flex justify-center text-white'
                                                    :
                                                    'bg-red-500 border w-16 font-bold border-red-500 rounded-md flex justify-center text-white'
                                                }>
                                                {i.active ? "Ativo" : "Inativo"}
                                            </p>
                                        </div>
                                </div>

                                <div className='flex flex-col gap-2 items-center justify-center'>
                                { i.developed&&
                                            <p className='
                                            bg-orange-400 border flex justify-center p-1 text-sm border-orange-500 
                                            bg-opacity-20 text-white font-bold rounded-md'>
                                                Developed
                                            </p>
                                        }
                                        { i.audited&&
                                            <p className='
                                            bg-red-400 border p-1 flex justify-center  text-sm
                                             border-red-500 bg-opacity-20 text-white font-bold rounded-md'>
                                                Audited
                                                </p>
                                        }
                                </div>

                                <div className='flex flex-col justify-center items-center gap-5'>
                                    { i.git &&
                                    <a href={i.link} className='flex items-center'>
                                        <img src={i.git}  alt='icon'/>
                                    </a>
                                    }
                                    
                                    { i.fig &&
                                    <a href={i.link2} className='flex items-center'>
                                        <img src={i.fig}  alt='icon'/>
                                    </a>
                                    }
                                    
                                </div>
                        </div>
                    ))}
            </article>
        
            <article className='flex justify-center'>
                <section className="flex items-center justify-center mt-10 border border-purple-primary mb-20 text-white rounded-xl lg:w-6/12">
                    <div>
                        <div className=" flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                            <p className="text-2xl font-bold">{newClient}</p>
                            <p className="font-semibold">Clients</p>
                        </div>

                        <div className="flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                            <p className="text-2xl font-bold">{newDesign}</p>
                            <p className="font-semibold text-center">Design Experience</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col items-center h-28 justify-center  bg-opacity-30 w-40">
                            <p className="text-2xl font-bold">{newFindings}</p>
                            <p className="font-semibold text-center">Fidings</p>
                        </div>

                        <div className=" flex flex-col items-center  h-28 justify-center bg-opacity-30 w-40">
                            <p className="text-2xl font-bold">{newSmart}</p>
                            <p className="font-semibold text-center">Smart Developer</p>
                        </div>
                    </div>
                </section>
            </article>
            
            <Footer/>
        </div>
    );
}
