import LogoNoBg from '../../../assets/img/logo-nobg.png';
import { WalletConnect } from '../../../components/WalletConnect';

export const SignUp = (): JSX.Element => {
    

    return (
        <div className="bg-gray-800 h-screen">
            <div>
                
                <div className='flex items-center justify-center'>
                    <img className='w-20' src={LogoNoBg} alt='icon'/>
                </div>

                <div className='flex flex-col justify-center items-center mt-32 gap-3'>
                    <h1 className='text-white text-2xl pl-10'>Connect your wallet to be reseller!</h1>
                   <WalletConnect/>
                </div>

            </div>

        </div>
    );
};

export default SignUp;
