type Props = {
    title: string;
}
export const CardItem = ({title}:Props):JSX.Element => {

    return (
        <div className="bg-gradient-card-items w-36  h-36 max-w-36 max-h-36 flex items-center justify-center rounded-xl
        hover:opacity-60 hover:scale-110
        ">   
            <p className="text-white font-semibold">{title}</p>
        </div>
    )

}
