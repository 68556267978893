import BASE from '../../assets/img/base.svg';
import ETH from '../../assets/img/eth.svg';
import POLYGON from '../../assets/img/polygon.svg';
import BINANCE from '../../assets/img/binance.svg';
import ARB from '../../assets/img/arb.svg';
import AVAX from '../../assets/img/avalanche.svg';
import TRON from '../../assets/img/tron.svg';
import GN from '../../assets/img/gn.svg';
import Lumx from "../../assets/img/lumx.png";
import LuizTools from "../../assets/img/luiztools.png";
import Marquee from "react-fast-marquee";

export const Carousel = () => {
  return (

    <Marquee className='flex justify-center items-center' speed={100} direction="left" loop={0} gradient={false}>
      <div className='ml-20'>
        <img src={BASE} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={ETH} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={POLYGON} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={BINANCE} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={ARB} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={AVAX} alt='icon'  />
      </div>

      <div className='ml-14'>
        <img className='w-56' src={Lumx} alt='icon'  />
      </div>

      <div className='ml-20'>
        <img src={TRON} alt='icon'/>
      </div>

      <div className='ml-20'>
        <img src={GN} alt='icon'  />
      </div>

      <div className='ml-16'>
        <img className='w-72' src={LuizTools} alt='icon'  />
      </div>
    </Marquee>
  );
};

export default Carousel;
