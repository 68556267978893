import { Component, ReactNode } from "react";

interface CardProps {
    image: string;
    title: string;
    paragraph: string;
}

export class Card extends Component<CardProps> {

    constructor(props: CardProps){
        super(props);

        this.state = {
            image: props.image || " ",
            title: props.title || " ",
            paragraph: props.paragraph || " "
        }
    }

    render(): ReactNode {
        return (
            <div className="max-w-auto">
                <div className="bg-white w-80 rounded-lg border p-2 border-slate-400 h-72 drop-shadow-xl lg:w-auto lg:max-w-[400px]">
                    <div className="w-8 h-8 bg-purple-primary rounded-2xl mb-2 animate-pulse"></div>
                    <hr className="w-full bg-slate-800"></hr>

                    <div className="flex flex-col gap-2 p-2">
                        <li className="font-bold text-blue-secundary">{this.props.title}</li>
                        <p className="text-blue-secundary">
                            {this.props.paragraph}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
};