import { useState, useEffect } from "react";
import { CardProducts } from "../../components/CardProducts";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeFi from '../../assets/products/DEFI.png';
import Tokens from '../../assets/products/tokens.png';
import Security from '../../assets/products/sec.png';
import { useTranslation } from 'react-i18next';

export const Products = (): JSX.Element => {
    const { t } = useTranslation();
    const [state, setState] = useState<any>([]);

    // Atualiza os textos sempre que o idioma mudar
    useEffect(() => {
        setState([
            {
                image: Security,
                title: t('security_audit.title'),
                paragraph: t('security_audit.paragraph'),
                item1: t('security_audit.evaluation'),
                item2: t('security_audit.reports'),
                item3: t('security_audit.compliance'),
                button: t('security_audit.audit_now'),
                link: " ",
                icon: " ",
                href: "security",
            },
            {
                image: DeFi,
                title: t('defi.title'),
                paragraph: t('defi.paragraph'),
                item1: t('defi.accessibility'),
                item2: t('defi.transparency'),
                item3: t('defi.innovation'),
                button: t('defi.create_now'),
                link: t('defi.learn_more'),
                icon: <ArrowForwardIosIcon />,
                href: "defi"
            },
            {
                image: Tokens,
                title: t('solucoes_tokens.title'),
                paragraph: t('solucoes_tokens.paragraph'),
                item1: t('solucoes_tokens.conversion'),
                item2: t('solucoes_tokens.security'),
                item3: t('solucoes_tokens.integration'),
                button: t('solucoes_tokens.explore_now'),
                link: t('solucoes_tokens.learn_more'),
                icon: <ArrowForwardIosIcon />,
                href: "tokens"
            }
        ]);
    }, [t]); // Dependência em 't' garante que o efeito seja executado ao mudar de idioma

    return (
        <div className="bg-white h-auto flex flex-col items-center gap-52 lg:pb-10 lg:pt-20 pb-20">
            {state.map((item:any, index:any) => (
                <CardProducts
                    key={index}
                    image={item.image}
                    title={item.title}
                    paragraph={item.paragraph}
                    item1={item.item1}
                    item2={item.item2}
                    item3={item.item3}
                    button={item.button}
                    link={item.link}
                    icon={item.icon}
                    href={item.href}
                />
            ))}
        </div>
    );
};
