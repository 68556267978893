import {ethers} from 'ethers';
import abi from "./abi.json";
import abiNft from './nft.json';


export function wallet(){
    const wallet = localStorage.getItem('wallet');
    return wallet;
}

function GetProvider(): ethers.BrowserProvider {
    if(!window.ethereum) throw new Error("Wallet not found!");
        return new ethers.BrowserProvider(window.ethereum);
};

export async function connectWallet(deepLink:string): Promise<string | void> {
    if (typeof window.ethereum !== 'undefined') {
        try {
            const provider = await GetProvider();
            const accounts = await provider.send('eth_requestAccounts', []);
            if (!accounts || !accounts.length) throw new Error('Wallet not found/allowed');
            localStorage.setItem('wallet', accounts[0]);
            return accounts[0];
        } catch (error) {
            console.error('User rejected the request or there was an error:', error);
        }
    } else {
        const metamaskDeepLink = deepLink;
        window.location.href = metamaskDeepLink;
    };
};



export async function signMessage(){
    const message = "this message only verify wallet. @nextchain";

    try {
        const provider = GetProvider();
        const signer = provider.getSigner();
        const signature = (await signer).signMessage(message);
        signature.then(res => {
            localStorage.setItem('sign', res); 
        });

        return signature;


    }
    catch(err){
        console.error(err);
    }
}

export async function verifyMessage() {
    const message = "this message only verify wallet. @nextchain";
    const signature = localStorage.getItem('sign');
    const walletAddress = localStorage.getItem('wallet');

    if (!signature || !walletAddress) {
        console.error('No signature or wallet address found in local storage');
        return;
    }

    try {
        const recoveredAddress = ethers.verifyMessage(message, signature);
        console.log("Recovered Address:", recoveredAddress);
        console.log("Stored Wallet Address:", walletAddress);
        console.log(recoveredAddress.toLowerCase() === walletAddress.toLowerCase());
        return recoveredAddress.toLowerCase() === walletAddress.toLowerCase();
    }
    catch (err) {
        console.error('Error verifying message:', err);
    }
}

 async function getContract(){
    
    const provider = new ethers.JsonRpcProvider("https://polygon-rpc.com");
    const contract = await new ethers.Contract("0x35c992E2a4D4c75a27EBF4A814487eD92B1c6a55", abi as ethers.InterfaceAbi, provider);

    return contract as ethers.Contract
}


export async function check(code: number) {
    const contract = await getContract();
    const data = await contract.check(code);
    return data;
}

export const privateKey = process.env.REACT_APP_PRIVATE

const provider = new ethers.JsonRpcProvider("https://rpc-amoy.polygon.technology");
const signWallet = new ethers.Wallet(String(privateKey), provider);

async function getNftContract(){
    const contract = await new ethers.Contract("0xaac166724BcA92Da5B7614e92178a4ca4c6F4C38", abiNft as ethers.InterfaceAbi, signWallet);

    return contract as ethers.Contract;
}

export async function getTokenId(){
    try {
        const contract = await getNftContract();
        const tokenId = await contract._nextTokenId()
        return tokenId;
    }
    catch(err){
        console.log(err);
    }
}

export async function safeMint(to:string) {
    try {
        const contract = await getNftContract();
        const tx = await contract.safeMint(to);
        const recipient = await tx.wait();
        const transactionHash = recipient.hash;
        console.log(transactionHash);
        return transactionHash;

    }catch(err){
        console.log(err);
    }
}

