import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routers } from './router/router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Slide } from 'react-toastify';
import './index.css';
import "./i18n/i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Routers/>
    <ToastContainer
    autoClose={3000}
    transition={Slide}
    
    />
  </React.StrictMode>
);

