import { useState, useEffect } from 'react';
import Logo from '../../assets/img/logo-redonda.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { ModalHeaderMobile } from '../ModalHeaderMobile';
import { t } from 'i18next';

export const HeaderMobile = (): JSX.Element => {
    const [modalControl, setModalControl] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState<number>(0);

    const handleClickOpenMenu = (): void => {
        setModalControl(!modalControl);
    };

    const handleScroll = (): void => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`p-4 z-20 transition-all duration-300 flex items-center w-screen ${scrollY < 100 ? '' : 'fixed top-0 bg-black'}`}>
            <div className='flex md:justify-between'>
                <a href='/'>
                    <img className={`w-10 ${scrollY > 100 ? 'border rounded-md' : ''}`} src={Logo} alt="logo" />
                </a>

                <div className='ml-20 flex gap-10'>
                    <a
                        className='bg-[#6d04ff] text-white text-center rounded-md flex justify-center font-semibold w-auto p-2'
                        href="https://api.whatsapp.com/send?phone=5513974221906&text=Ol%C3%A1,%20fui%20redirecionado%20via%20(website)%20e%20gostaria%20de%20saber%20mais%20sobre%20seus%20produtos%20e%20servi%C3%A7os.%20%F0%9F%98%83%0A%0A">
                        {t("header.request")}
                    </a>

                    <button onClick={handleClickOpenMenu}>
                        <MenuIcon style={{ fontSize: "30px" }} className={`${scrollY < 100 ? 'text-black' : 'text-white'}`} />
                    </button>

                    {modalControl && (
                        <>
                            <div onClick={handleClickOpenMenu} className='absolute bg-transparent w-screen h-screen left-0'></div>
                            <ModalHeaderMobile />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};
