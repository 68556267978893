import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateBR from '../locales/pt.json';
import translateEN from '../locales/en.json';

const resources = {
  pt: {
    translation: translateBR
  },
  en: {
    translation: translateEN
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt', 
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
