import { useTranslation } from 'react-i18next';

export const Footer = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <footer className="bg-white h-auto">
            <div className="p-2">
                <h2 className="text-slate-500 text-xl font-bold">
                    {t('newsletter.title')}
                </h2>
                <div className="border border-slate-400 flex gap-1 p-[2px] rounded-lg justify-between max-w-[85%] mt-2">
                    <input className="p-1" placeholder={t('newsletter.placeholder')} />
                    <button className="bg-slate-500 p-1 h-10 rounded-lg text-sm text-white font-semibold">
                        {t('newsletter.subscribe')}
                    </button>
                </div>
                <p className="text-blue-secundary mt-2">
                    {t('newsletter.disclaimer')}
                </p>
            </div>

            <div className="grid grid-cols-2 p-2 gap-2 mt-5">
                <ul className="flex flex-col">
                    <p className="text-slate-600 text-xl font-semibold">{t('footerName.service')}</p>
                    <a href="/defi" className="text-slate-500">{t('services.defi')}</a>
                    <a href="/dapps" className="text-slate-500">{t('services.dapps')}</a>
                    <a href="/security" className="text-slate-500">{t('services.security_audit')}</a>
                    <a href="/tokens" className="text-slate-500">{t('services.token_solutions')}</a>
                    <a href="/nfts" className="text-slate-500">{t('services.nft_solutions')}</a>
                </ul>

                <ul className="flex flex-col">
                    <p className="text-slate-600 text-xl font-semibold">{t('footerName.companyname')}</p>
                    <a href="/about" className="text-slate-500">{t('company.about')}</a>
                    <a href="/coin" className="text-slate-500">{t('company.next_coin')}</a>
                    <a href="/ressel" className="text-slate-500">{t('company.careers')}</a>
                </ul>

                <ul className="flex flex-col">
                    <p className="text-slate-600 text-xl font-semibold">{t('footerName.social')}</p>
                    <a href="https://x.com/nxchainofc" className="text-slate-500">X</a>
                    <a href="https://www.instagram.com/nxchainofc" className="text-slate-500">{t('social_media.instagram')}</a>
                    <a href="https://www.youtube.com/@nxChain" className="text-slate-500">{t('social_media.youtube')}</a>
                    <a href="/criar" className="text-slate-500">{t('social_media.telegram')}</a>
                </ul>
            </div>

            <div className="p-5">
                <div className="flex items-center gap-5 lg:justify-center">
                    <a href="/policy" className="text-sm text-slate-500">{t('terms.terms_of_service')}</a>
                    <hr className="w-5 rotate-90 bg-black"></hr>
                    <a href="/privacy" className="text-sm text-slate-500">{t('terms.privacy_policy')}</a>
                    <hr className="w-5 rotate-90 bg-black"></hr>
                    <a href="/cookie" className="text-sm text-slate-500">{t('terms.no_cookies')}</a>
                </div>
                <hr className="w-full bg-slate-600"></hr>
                <div className='flex justify-between'>
                    <a href="/" className="text-sm text-slate-500">{t('footer.copyright')}</a>
                    <label className="text-sm text-slate-500">CNPJ-57.836.618/0001-03</label>
                </div>
            </div>
        </footer>
    );
};
