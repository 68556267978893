import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from '@mui/icons-material/Telegram';
import { FormEvent, useState } from "react";
import { api } from "../../service/api";

export const ContactArea = (): JSX.Element => {

  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [notes, setNotes] = useState<string>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {

    e.preventDefault();

    try {
        const {data} = await api.post("quote", {
            email: email,
            fullname: name,
            notes: notes

        });

        return data;
    }
    catch(err) {
      console.log(err)
      return err;
    }
  }
  return (
    <form  noValidate onSubmit={handleSubmit} className="min-h-screen flex flex-col justify-center items-center bg-black p-4">
      <div className="w-full max-w-2xl border-2 border-gray-400 rounded-lg p-8 bg-white lg:max-w-4xl">
        <div className="flex justify-center mt-4">
          <h1 className="font-bold text-3xl lg:text-4xl text-center">
            Contate-nos
          </h1>
        </div>

        <div className="flex justify-center mt-4">
          <h2 className="font-medium px-5 text-center text-lg lg:text-xl">
            Alguma pergunta ou observação? Entre em contato conosco.
          </h2>
        </div>

        <div className="mt-6">
          <div className="md:flex md:gap-8 lg:gap-12">
            <div className="flex justify-center items-center mt-4 md:mt-0 md:flex-1">
              <input
                type="email"
                placeholder="Digite seu E-mail"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border-2 border-neutral-950 h-12 p-2 rounded-lg lg:h-14"
              />
            </div>
            <div className="flex justify-center items-center mt-4 md:mt-0 md:flex-1">
              <input
                type="text"
                placeholder="Digite seu Nome"
                onChange={(e) => setName(e.target.value)}
                className="w-full border-2 border-neutral-950 h-12 p-2 rounded-lg lg:h-14"
              />
            </div>
          </div>
          <div className="flex justify-center items-center mt-4">
            <textarea
              placeholder="Digite sua mensagem"
              onChange={(e) => setNotes(e.target.value)}
              className="w-full border-2 border-neutral-950 h-32 p-2 rounded-lg resize-none lg:h-40"
            ></textarea>
          </div>

          <div className="flex items-center justify-center mt-6">
            <button className="bg-purple-primary text-white h-10 w-40 font-bold rounded-lg hover:bg-blue-600 transition duration-300 lg:h-12 lg:w-48">
              Enviar
            </button>
          </div>
        </div>

        <div className="flex justify-center mt-10 space-x-4">
          <div className="flex flex-col items-center justify-center">
            <a
              href="https://wa.me/5513974221906"
              className="text-lg font-medium lg:text-xl"
            >
              <WhatsAppIcon className="text-green-500 mr-2" />
              (13) 97422-1906
            </a>
            <a
              href="https://t.me/nxchainofc"
              className="text-lg font-medium lg:text-xl"
            >
              <TelegramIcon className="text-blue-500 mr-2" />
              @nxchainofc
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center mt-6">
        </div>
      </div>
    </form>
  );
};
