import X from '../../assets/midias/x.svg';
import Telegram from '../../assets/midias/telegram.svg';
import Youtube from '../../assets/midias/youtube.svg';
import Instagram from '../../assets/midias/instagram.svg';
import WalletIcon from '@mui/icons-material/Wallet';
import { useState, useEffect } from 'react';
import { connectWallet, signMessage, verifyMessage } from '../../service/Blockchain';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const WalletConnect = (): JSX.Element => {
    const [state, setState] = useState(false);
    const [control, setControl] = useState(false);
    const navigate = useNavigate();

    const handleCloseWallet = () => {
        setState(!state);
    }

    const wallet = async (deepLink: string) => {
        try {
           const res = await connectWallet(deepLink);

           if(res){
              await setState(false);
              await setControl(true);
              await toast.success('Carteira conectada com sucesso!🚀')
           }
        } catch (err) {
            toast.error('Carteira não encontrada ou permissão negada! 🚨')
        }
    };

    const signAndVerify = async () => {
        try {
            await toast.promise(
                signMessage(),
                {
                    pending: "assinando...⚙",
                    success: "verificando...mensagem assinada com sucesso 🚀",
                    error: "Wallet not found or not approved! 🚨"
                }
            );

            await verifyMessage();
            navigate('/resell');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className='w-screen'>
            {!state &&
                <div className='flex justify-center'>
                    <button onClick={!control ? handleCloseWallet : signAndVerify} className='bg-white w-10/12 h-10 rounded-md lg:w-96'>
                        {!control ? "Connect Wallet" : "Assine esta mensagem"}
                    </button>
                </div>
            }

            {state &&
                <div className="bg-white absolute bottom-0 h-[80%] overflow-y-auto w-full left-0 rounded-tl-lg rounded-tr-lg 
                    lg:w-96 lg:h-auto lg:relative lg:ml-[41%] lg:mt-10 lg:rounded-xl lg:pb-5 flex flex-col items-center justify-center">

                    <div className='flex flex-col'>
                        <div className='flex justify-center gap-10 pt-5'>
                            <img src={X} alt='icon' />
                            <img src={Telegram} alt='icon' />
                            <img src={Youtube} alt='icon' />
                            <img src={Instagram} alt='icon' />
                        </div>

                        <div className='flex flex-col  items-center mt-20 gap-2'>
                            <button className='w-10/12 bg-slate-200 rounded-xl flex items-center p-1 gap-2 '
                                onClick={() => wallet('https://metamask.app.link/dapp/https://nxchain-website.vercel.app/resell/signup')}>
                                <img className='w-16'
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/2048px-MetaMask_Fox.svg.png"
                                    alt='icon'
                                />
                                MetaMask
                            </button>

                            <button
                                onClick={() => wallet('https://safepal.io/app/dapp/https://nxchain-website.vercel.app/resell/signup')}
                                className='w-10/12 bg-slate-200 rounded-xl flex items-center p-1 gap-2'>
                                <img className='w-16 rounded-xl'
                                    src="https://pbs.twimg.com/profile_images/1676254262505123840/NhRRmBnl_400x400.png"
                                    alt='icon'
                                />
                                SafePal
                            </button>

                            <button
                                onClick={() => wallet('https://app.uniswap.org/dapp/https://nxchain-website.vercel.app/resell/signup')}
                                className='w-10/12 bg-slate-200 rounded-xl flex items-center gap-2 p-1 '>
                                <img className='w-16 rounded-xl'
                                    src="https://play-lh.googleusercontent.com/rJF2Bcp9EDfARTSY-7DmYnX0Q6cX3zBwXm2RSUjjDInUObbiM1iVKfjXKZRS2BP7gSE=w240-h480-rw"
                                    alt='icon'
                                />
                                Uniswap Wallet
                            </button>

                            <button
                                onClick={() => wallet('https://link.coinbase.com/dapp/https://nxchain-website.vercel.app/resell/signup')}
                                className='w-10/12 bg-slate-200 rounded-xl flex items-center p-1 gap-2 '>
                                <img className='w-16 rounded-xl'
                                    src="https://play-lh.googleusercontent.com/wrgUujbq5kbn4Wd4tzyhQnxOXkjiGqq39N4zBvCHmxpIiKcZw_Pb065KTWWlnoejsg"
                                    alt='icon'
                                />
                                Coinbase Wallet
                            </button>

                            <button
                                onClick={() => wallet('')}
                                className='w-10/12 bg-slate-200 rounded-xl flex items-center p-1 gap-2 '>
                                <WalletIcon className='text-blue-primary' style={{ fontSize: "60px" }} />
                                All Wallets
                            </button>
                        </div>

                        <div className='flex justify-center mt-5'>
                            <button onClick={handleCloseWallet} className='bg-blue-primary w-10/12 h-10 rounded-lg text-white font-semibold'>Voltar</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
