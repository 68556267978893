import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LanguageIcon from '@mui/icons-material/Language';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ModalHeaderMobile = (): JSX.Element => {
    const [submodal, setSubModal] = useState<any>({ position: 1, active: false });
    const { i18n, t } = useTranslation();

    const handleControlSubModals = (index: number): void => {
        setSubModal({ position: index, active: true });
    };

    const closeSubModal = () => {
        setSubModal({ active: false });
    };

    const toggleLanguage = () => {
        i18n.changeLanguage(i18n.language === 'pt' ? 'en' : 'pt');
    }

    return (
        <>
            {!submodal.active && (
                <div className="absolute mt-20 flex flex-col w-full left-0 border-2 border-purple-primary rounded-lg">
                    <div className='flex flex-col bg-white w-full rounded-md gap-2 justify-start items-start'>
                        <a href='https://blog.nxchain.link/' target='_blank' rel="noreferrer" className='p-2'>{t('header.links.blog')}</a>
                        <hr className="bg-slate-700 w-full" />
                        <div className="w-full flex flex-col items-start gap-1">
                            {/* <button 
                                onClick={() => handleControlSubModals(2)} 
                                className="mt-2 ml-2 flex justify-between w-[95%]">
                                {t('header.links.service')}
                                <ArrowForwardIosIcon />
                            </button> */}
                            {/* <hr className="bg-slate-700 w-full" /> */}
                        </div>
                        <div className="w-full flex flex-col items-start gap-1">
                            <button 
                                onClick={() => handleControlSubModals(3)} 
                                className="mt-2 ml-2 flex justify-between w-[95%]">
                                {t('header.company')}
                                <ArrowForwardIosIcon />
                            </button>
                            <hr className="bg-slate-700 w-full" />
                        </div>
                        <div className="w-full flex flex-col items-start gap-1">
                            <button 
                                onClick={() => handleControlSubModals(4)} 
                                className="mt-2 ml-2 flex justify-between w-[95%]">
                                {t('header.links.contact')}
                                <ArrowForwardIosIcon />
                            </button>
                            <hr className="bg-slate-700 w-full" />
                        </div>
                        <div className="h-20 flex items-center justify-center w-full">
                            <div className="flex gap-10">
                                <a href='https://www.instagram.com/nxchainofc' className="bg-purple-primary p-1 px-2 rounded-md text-white">
                                    Instagram
                                </a>
                                <a href='https://whatsapp.com/channel/0029Vak7Mtt23n3WLxTbGt22' className="border border-purple-primary p-1 px-6 rounded-md text-black">
                                    {t("header.community")}
                                </a>
                                <button onClick={toggleLanguage}>
                                    <LanguageIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {submodal.active && (
                <div className={`w-[90%] bg-white rounded-md absolute mt-20 left-0 ml-5 animate-slideIn`}>
                    <div className='p-1 py-2'>
                        <button onClick={closeSubModal} className='text-blue-primary font-semibold'>
                            <ArrowBackIosNewIcon className='text-purple-primary' /> {t('header.links.back')}
                        </button>
                        <hr className='w-full bg-slate-700 mt-2' />
                    </div>
                    <div className='flex flex-col'>
                        {/* {submodal.position === 2 && (
                            <>
                                <div>
                                    <a href='/defi' className='ml-2'>{t('header.service.defi')}</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                                <div className='mt-2'>
                                    <a href='/security' className='ml-2'>{t('header.service.audit')}</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                                <div className='mt-2'>
                                    <a href='/tokens' className='ml-2'>{t('header.service.solutions')}</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                                <div className='mt-2'>
                                    <a href='/nfts' className='ml-2'>{t('header.service.nft')}</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                            </>
                        )} */}
                        {submodal.position === 3 && (
                            <div className='flex flex-col gap-2'>
                                <a href='/about' className='ml-2'>{t('header.company')}</a>
                                <a href='/portfolio' className='ml-2'>Portfolio</a>
                                <hr className='w-full bg-slate-700 mt-2' />
                            </div>
                        )}
                        {submodal.position === 4 && (
                            <>
                                <div>
                                    <a href='https://t.me/nxchainofc' className='ml-2'>Telegram: @nxchainofc</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                                <div className='mt-2'>
                                    <a href='mailto:nextchain@nxchain.link' className='ml-2'>E-mail</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                                <div className='mt-2'>
                                    <a href='https://api.whatsapp.com/send?phone=5513974221906&text=Ol%C3%A1,%20fui%20redirecionado%20via%20(website)%20e%20gostaria%20de%20saber%20mais%20sobre%20seus%20produtos%20e%20servi%C3%A7os.%20%F0%9F%98%83%0A%0A' className='ml-2'>WhatsApp</a>
                                    <hr className='w-full bg-slate-700 mt-2' />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
