import { useState, useEffect } from "react";
import { verifyMessage } from "../service/Blockchain";
import { Navigate } from "react-router-dom";

type Props = {
  children: JSX.Element;
}



export const PrivateRoute = ({ children }: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  
    useEffect(() => {
      const checkAuthentication = async () => {
        const data :any= await verifyMessage();
        setIsAuthenticated(data);
      };
  
      checkAuthentication();
    }, []);
  
    if (isAuthenticated === null) {
      return <div>Loading...</div>;
    }
  
    return isAuthenticated ? children : <Navigate to="/resell/signup" />;
  };