import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Resell from '../../assets/img/banner.png';
import { useTranslation } from 'react-i18next';

export const Ressel = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="h-auto bg-black lg:flex lg:flex-row lg:justify-around">
            <div className="flex justify-center items-center ml-5">
                <img className='p-5 lg:w-[700px] lg:h-[400px] rounded-xl' src={Resell} alt='resell-img' />
            </div>

            <div className='p-5 mt-10 pl-20'>
                <h1 className='text-xl text-white font-bold lg:text-3xl lg:w-[60%]'>
                    {t('resell.title')}
                </h1>
                <p className='text-white text-sm mt-1 lg:text-xl lg:w-[70%]'>
                    {t('resell.paragraph')}
                </p>

                <ul className='mt-10 flex flex-col gap-5'>
                    <li className='text-white flex gap-2'>
                        <CheckCircleIcon className='text-purple-primary' />
                        {t('resell.premium_products')}
                    </li>
                    <li className='text-white flex gap-2'>
                        <CheckCircleIcon className='text-purple-primary' />
                        {t('resell.competitive_commissions')}
                    </li>
                    <li className='text-white flex gap-2'>
                        <CheckCircleIcon className='text-purple-primary' />
                        {t('resell.payment_flexibility')}
                    </li>
                    <li className='text-white flex gap-2'>
                        <CheckCircleIcon className='text-purple-primary' />
                        {t('resell.exclusive_support')}
                    </li>
                </ul>

                <h4 className='text-white font-bold text-md mt-5 lg:text-xl'>
                    {t('resell.transform_profit')}
                </h4>

                <div className='flex gap-5 mt-5 items-center lg:mt-10'>
                    <a 
                        href='https://pdf-nextchain.s3.us-east-1.amazonaws.com/Next+Chain+Blockchain+Solutions+LTDA.pdf' 
                        className='bg-purple-primary text-white p-2 rounded-lg shadow-2xl'
                    >
                        {t('resell.learn_more')}
                    </a>
                </div>
            </div>
        </div>
    );
};
