import { CardService } from "../../../components/CardService";
export const CardBenefits = (): JSX.Element => {
  return (
    <div className="flex justify-center mt-20 pb-10">
      <div className="flex flex-col justify-center items-center w-80 md:w-full gap-10 lg:flex-row lg:gap-15 lg:w-full">
        <CardService
          title="Inovação Tecnológica"
          p="Na Next, nossas soluções de tokens utilizam a mais recente tecnologia para garantir que suas transações sejam rápidas, seguras e eficientes, proporcionando a base perfeita para o crescimento do seu negócio.  "
        />

        <CardService
          title="Vantagem Competitiva"
          p="Aumente sua vantagem competitiva com as soluções de tokens da Next. Operando com eficiência aprimorada e reduzindo custos, você está preparado para se destacar no mercado e alcançar novos patamares de sucesso empresarial."
        />

        <CardService
          title="Confiança e Transparência"
          p="Construa relacionamentos sólidos baseados em confiança e transparência com as soluções de tokens da Next. Garantimos total visibilidade em suas transações digitais, fortalecendo a credibilidade da sua empresa e proporcionando tranquilidade aos seus clientes."
        />
      </div>
    </div>
  );
};
