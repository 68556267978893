import { HeaderComputer } from "../../../components/HeaderComputer";
import { HeaderMobile } from "../../../components/HeaderMobile";
import NFT from '../../../assets/products/nft.png';
import { Benefits } from "../../../pages/NftSolutions/Benefits";
import { ContactArea } from "../../../components/ContactArea";

import { Footer } from "../../../components/Footer";

export const NftSolutions = (): JSX.Element => {
  return (
    <div className="bg-white h-screen">
      <div className="lg:hidden">
        <HeaderMobile />
      </div>
      <div className="hidden lg:block">
        <HeaderComputer />
      </div>

      <div className="p-5 flex flex-col lg:flex-row-reverse lg:items-center lg:justify-center lg:px-20 lg:mt-32 lg:mb-50">
        <div className="flex justify-center mt-10">
          <img className="lg:w-[3300px]" src={NFT} alt="icon"/>
        </div>
        <div className="mt-10 flex flex-col gap-1">
          <h1 className="text-purple-primary font-bold lg:text-2xl">
            Nft's Solutions
          </h1>
          <h2 className="text-black font-bold text-3xl lg:text-5xl">
            Revolucione seus Ativos Digitais: Soluções NFT que Transformam o
            Futuro do Seu Negócio (Nft's Solutions)
          </h2>
          <p className="text-sm text-slate-500 lg:w-[60%] lg:text-xl">
            Transforme a forma como você gerencia e valoriza seus ativos
            digitais. Com nossa tecnologia inovadora, você pode criar, vender e
            proteger NFTs com facilidade e segurança. Nossas soluções garantem
            autenticidade e exclusividade, permitindo que você monetize de
            maneira eficiente. Dê o próximo passo na revolução digital, envolva
            sua audiência com experiências únicas e impulsione seu negócio para
            o futuro com a Next!
          </p>

          <div className="flex gap-5 mt-5 items-center">
          </div>
        </div>
      </div>

      <Benefits />

      <ContactArea />

      <Footer />
    </div>
  );
};
