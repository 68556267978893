import { HeaderComputer } from "../../../components/HeaderComputer";
import { HeaderMobile } from "../../../components/HeaderMobile";
import { CardBenefits } from "../../../pages/Defi/Benefits";
import { ContactArea } from "../../../components/ContactArea";
import DEFI from '../../../assets/products/DEFI.png';

import { Footer } from "../../../components/Footer";

export const Defi = (): JSX.Element => {
  return (
    <div className="bg-white h-screen">
      <div className="lg:hidden">
        <HeaderMobile />
      </div>
      <div className="hidden lg:block">
        <HeaderComputer />
      </div>

      <div className="p-5 flex flex-col lg:flex-row-reverse lg:items-center lg:justify-center lg:px-20 lg:mt-32 lg:mb-50">
        <div className="flex justify-center mt-10">
          <img src={DEFI} alt="icon"/>
        </div> 
        <div className="mt-10 flex flex-col gap-1">
          <h1 className="text-purple-primary font-bold lg:text-2xl">DeFi</h1>
          <h2 className="text-black font-bold text-3xl lg:text-5xl">
            o Poder das finanças descentralizadas (DeFi){" "}
          </h2>
          <p className="text-sm text-slate-500 lg:w-[60%] lg:text-xl">
            Aproveite esta oportunidade para acessar serviços mais transparentes
            e eficientes, economizando tempo e dinheiro. Invista em DeFi e
            transforme sua experiência financeira hoje mesmo!
          </p>

          <div className="flex gap-5 mt-5 items-center">
          </div>
        </div>
      </div>

      <CardBenefits />
      <ContactArea />

      <Footer />
    </div>
  );
};
