import { CardService } from "../../../components/CardService";
export const CardBenefits = (): JSX.Element => {
  return (
    <div className="flex justify-center mt-20 pb-10">
      <div className="flex flex-col justify-center items-center w-80 md:w-full gap-10 lg:flex-row lg:gap-15 lg:w-full">
        <CardService
          title="Inovação Financeira Next"
          p="Transformando o Futuro das Finanças Descubra como a Next está revolucionando o setor financeiro com soluções DeFi inovadoras. Simplifique suas transações financeiras e aproveite a segurança da blockchain.  "
        />

        <CardService
          title="Acesso Descentralizado Liberdade Financeira"
          p=" Você tem acesso a serviços financeiros sem intermediários. Aproveite a transparência e a eficiência das finanças descentralizadas para gerenciar seus ativos de forma independente."
        />

        <CardService
          title="Economize e Invista Next"
          p="Seu Dinheiro em Boas Mãos Com a Next, reduza os custos de transação e maximize seus investimentos. Desfrute da segurança e eficiência das finanças descentralizadas, colocando você no controle total dos seus recursos financeiros."
        />
      </div>
    </div>
  );
};
