import { useEffect, useRef, useState } from "react";
import blockies from 'ethereum-blockies';
import { wallet } from "../../../hooks/useUser";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CardItem } from "../../../components/CardItem";
import Diamond from '../../../assets/rank/dimond.png';
import { NavBar } from "../../../components/NavBar";

export const HomeReseller = ():JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const[clip, setClip] = useState<boolean>(false);
    const data:any = wallet();

    useEffect(() => {
        if (canvasRef.current) {
            const icon = blockies.create({
                seed: data.toLowerCase(),
                size: 8,
                scale: 10,
            });

            const ctx = canvasRef.current.getContext('2d');
            if (ctx) {
                // Limpa o canvas antes de desenhar
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                // Define o tamanho do canvas baseado no tamanho do ícone
                ctx.canvas.width = icon.width;
                ctx.canvas.height = icon.height;

                // Desenha o ícone no centro do canvas
                ctx.drawImage(icon, 0, 0, icon.width, icon.height);

                // Aplica a borda arredondada
                ctx.canvas.style.borderRadius = "50%";
            }
        }
    }, [data]);

    
    const handleCopy = () => {
        navigator.clipboard.writeText(data)
        .then(() => {
            setClip(true);

            setTimeout(() => setClip(false), 2000);
        });
        
    };

    return (
        <div className="bg-gray-800 h-auto flex justify-center flex-col">
            <div className="flex flex-col items-center text-center mt-10">
                <canvas ref={canvasRef}>
                   
                </canvas>

               <div className="flex items-center gap-2">
                    <p className="text-white truncate max-w-72 mt-4">
                            {data}
                    </p>

                    <button onClick={handleCopy}>
                        <ContentCopyIcon
                        className="text-white"
                        />
                    </button>
               </div>

                <p className="text-white absolute mt-20">
                        { clip && "Copiado!" }
                </p>
            
                <div className="grid grid-cols-2 gap-10 mt-10">
                    <CardItem title="Ebook"/>
                    <CardItem title="Vendas"/>
                    <CardItem title="Criar link"/>
                    <div className="w-40 pb-52">
                        <img className="w-36" src={Diamond} alt="rank"/>
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex gap-10 text-white items-centerR">
                                <p>100 vendas</p>
                                <p>Diamond</p>
                            </div>
                            <div className="w-40 rounded-md h-2 bg-white mt-2">
                                <div className="bg-blue-400 w-20 rounded-md h-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NavBar/>
        </div>
    );
}
