import { HeaderMobile } from "../../components/HeaderMobile"
import Banner from '../../assets/img/banner.png';
import  NFT from '../../assets/img/nft-discount.png';
import { Footer } from "../../components/Footer";
import { useEffect, useState } from "react";
import { safeMint, getTokenId} from "../../service/Blockchain";
import { HeaderComputer } from "../../components/HeaderComputer";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const MintPage = () => {
    const [wallet, setWallet] = useState<any>();
    const [id, setId] = useState<any>();
    const [state, setState] = useState(false);
    const [hash, setHash] = useState<any>();

    useEffect(() => {
        const load = async () => {
            const newId = await getTokenId();
            setId(newId);
        }

        load();
        
    }, [])  

    const handleControl =() => {
        setState(!state);
    }

    async function mintAndOpen(){
        if(wallet.length > 10){
            const txhash = await safeMint(wallet);
            setHash(txhash);
            await handleControl()
    
        }
        else {
            return;
        }
       
    }

    return (
        <div className="">
            <div className="lg:hidden">
          <HeaderMobile/>
        </div>
        
        <div className="hidden lg:block">
            <HeaderComputer />
        </div>
            <div className="flex justify-center">
                <img src={Banner} alt="banner"/>
            </div>
            
            <h1 className="p-2 text-xl text-center font-bold">Olá, Seja-bem vindo á 
                <span className="text-purple-primary ml-1">
                Next Chain.
                </span>
            </h1>

            <div className="p-10 flex flex-col justify-center items-center mb-10">
                <img className="border-2 border-slate-500 rounded-lg" src={NFT} alt="nft"/>
                <p className="font-semibold">Coloque seu endereço:</p>
                <input className="border-2 border-slate-500 w-10/12 mt-2 p-2 rounded-md" placeholder="0x8D4f693696F7F1fFe901F84f247A922DC1Dc9a6E" onChange={(e) => setWallet(e.target.value)}/>
                <button onClick={mintAndOpen} className="bg-purple-primary w-32 mt-2 p-1 rounded-lg text-white font-bold">Mint</button>
            </div>

            {
                state &&
                <div className="h-auto w-full absolute bg-black bg-opacity-95 top-20 p-5">
                    <button onClick={handleControl}><CloseIcon className="text-white" fontSize="large"/></button>
                        <div className="flex flex-col justify-center items-center mt-5">
                            <h2 className="text-xl text-purple-primary mt-5">Parabéns sua NFT foi gerada com sucesso! 🚀</h2>
                            <p className="text-white">Aproveite seus benefícios! Entre para nossa comunidade e resgate 10% de desconto em qualquer aplicação Web3.</p>
                            <CheckCircleIcon className="text-green-500 mt-10" fontSize="large"/>
                        </div>
                        <div className="text-sm flex flex-col mt-10 items-center lg:flex-row gap-1">
                            <p className="text-white">Informações para importar na wallet.</p>
                            <p><span className="text-purple-primary">0x8D4f693696F7F1fFe901F84f247A922DC1Dc9a6E</span></p>
                            <p className="font-bold text-white">NFT ID: {String(id).toString()}</p>
                        </div>

                        <div className="mt-24 flex justify-between">
                            <a className="bg-purple-primary p-2 rounded-md text-white" href={`https://amoy.polygonscan.com/tx/${hash}`}>View transaction</a>
                            <a className="bg-white p-2 rounded-md text-purple-primary" href="https://www.instagram.com/nxchainofc/">Instagram</a>
                        </div>
                </div>
            }

            <Footer/>
        </div>
    )
}