import { CardService } from "../../../components/CardService";
export const CardBenefits = (): JSX.Element => {
  return (
    <div className="flex justify-center mt-20 pb-10">
      <div className="flex flex-col justify-center items-center w-80 md:w-full gap-10 lg:flex-row lg:gap-15 lg:w-full">
        <CardService
          title="Auditoria Completa de Segurança"
          p="Investir em nossa Auditoria Completa de Segurança é a melhor decisão para proteger sua empresa contra ameaças cibernéticas. Identificamos e eliminamos vulnerabilidades, garantindo que seus dados e operações estejam seguros. Com a Next, você pode operar com confiança sabendo que sua segurança está em mãos experientes e dedicadas.  "
        />

        <CardService
          title="Monitoramento Contínuo"
          p="Nosso serviço de Monitoramento Contínuo oferece uma camada adicional de proteção. Detectamos e respondemos imediatamente a quaisquer atividades suspeitas, minimizando o impacto de potenciais violações de segurança. Ao escolher a Next, você está investindo na proteção proativa que mantém sua empresa segura 24 horas por dia, 7 dias por semana."
        />

        <CardService
          title="Conformidade e Tranquilidade"
          p="Garantimos que sua empresa esteja totalmente em conformidade com regulamentos de segurança. Evite multas e proteja sua reputação mantendo seus sistemas alinhados com as melhores práticas do setor. Com a Next, você não apenas cumpre com as normas exigidas, mas também fortalece sua imagem como um parceiro confiável e responsável."
        />
      </div>
    </div>
  );
};
